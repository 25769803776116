import { ReactElement, useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore/lite";
import { ParsedToken } from "firebase/auth";

import { db } from "../../firebase";
import FakeNumbersTable from "./Table";
import { useValidateAccess } from "../../authUtils";
import { useHttpsCallable } from "react-firebase-hooks/functions";
import { functions } from "../../firebase";
import { useShowNotification } from "../../Common/Notifications/useShowNotification";
import { HttpsCallableResult } from "firebase/functions";
import { Box, Button } from "@mui/material";
import AddFakeNumberDialog from "../../Common/NumbersPage/AddNumbersDialog";
import FakeNumber from "./FakeNumber";

type Props = {
  claims: ParsedToken;
};

const FakeNumbers = ({ claims }: Props): ReactElement => {
  const [fakeNumbers, setFakeNumbers] = useState<FakeNumber[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [addFakeNumberDialogOpen, setAddFakeNumberDialogOpen] =
    useState<boolean>(false);

  useValidateAccess(claims, ["admin", "admin_readonly"]);

  const showNotification = useShowNotification();

  const [callAddFakeNumber, adding, errorAdding] = useHttpsCallable<
    { numbers: string[] },
    { status: number; fakeNumbers: FakeNumber[] }
  >(functions, "addFakeNumber");

  const [callRemoveFakeNumber, removing, errorRemoving] = useHttpsCallable<
    { number: string },
    { status: number; fakeNumbers: FakeNumber[] }
  >(functions, "removeFakeNumber");

  useEffect(() => {
    const getFakeNumbers = async () => {
      setLoading(true);
      const fakeNumbersCollection = collection(db, "/fakeNumbers");
      const fakeNumbers = (await getDocs(fakeNumbersCollection)).docs.map(
        (doc) => doc.data() as FakeNumber
      );

      setFakeNumbers(fakeNumbers);
      setLoading(false);
    };

    getFakeNumbers();
  }, []);

  useEffect(() => {
    if (errorAdding) {
      showNotification(errorAdding.message, "error");
    }

    if (errorRemoving) {
      showNotification(errorRemoving.message, "error");
    }
  }, [errorAdding, errorRemoving]);

  const handleResponse = (
    response: HttpsCallableResult<{
      status: number;
      fakeNumbers: FakeNumber[];
    }>,
    onSuccess: () => void
  ) => {
    if (response?.data?.status === 201) {
      setFakeNumbers(response.data.fakeNumbers);
      showNotification("Fake Numbers have been updated", "success");
      onSuccess();
    }
  };

  const addFakeNumber = async (numbers: string[]) => {
    const response = await callAddFakeNumber({ numbers });
    handleResponse(response, () => setAddFakeNumberDialogOpen(false));
  };

  const removeFakeNumber = async (number: string, onSuccess: () => void) => {
    const response = await callRemoveFakeNumber({ number });
    handleResponse(response, onSuccess);
  };

  const canManage = Boolean(claims.admin);

  return (
    <>
      <Box sx={{ height: "100%" }}>
        {canManage && (
          <Button onClick={() => setAddFakeNumberDialogOpen(true)}>
            Add to fake numbers
          </Button>
        )}
        <FakeNumbersTable
          numbers={fakeNumbers}
          canManage={Boolean(claims.admin)}
          loading={loading || adding || removing}
          removeFromList={removeFakeNumber}
        />
      </Box>
      <AddFakeNumberDialog
        open={addFakeNumberDialogOpen}
        loading={loading || adding || removing}
        title={"Add fake number"}
        onClose={() => setAddFakeNumberDialogOpen(false)}
        onSubmit={addFakeNumber}
      />
    </>
  );
};

export default FakeNumbers;
