import { Box, Button, Typography } from "@mui/material";
import { ReactElement } from "react";
import { useNavigate } from "react-router-dom";

const ErrorPage = (): ReactElement => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <Typography component="h1" variant="h3">
        404 - Page not found
      </Typography>
      <Button
        type="button"
        variant="contained"
        sx={{ mt: 3, mb: 2, width: 240 }}
        onClick={() => navigate("/")}
      >
        Take me back
      </Button>
    </Box>
  );
};

export default ErrorPage;
