// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore/lite";
import { getFunctions } from "firebase/functions";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyB0i2exswnxTQaXXhDgmNYaf2T575Q4tpQ",
  authDomain: "weedo-b0efb.firebaseapp.com",
  databaseURL: "https://weedo-b0efb.firebaseio.com",
  projectId: "weedo-b0efb",
  storageBucket: "weedo-b0efb.appspot.com",
  messagingSenderId: "488601213773",
  appId: "1:488601213773:web:7b01c910449a3db737b504",
  measurementId: "G-4LSMEGFVMW",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const functions = getFunctions(app, "europe-west3");

export { auth, db, functions };
