import { Dialog } from "@mui/material";
import { ReactElement } from "react";
import AddNumbersForm from "./AddNumbersForm";

type Props = {
  open: boolean;
  loading: boolean;
  title: string;
  onSubmit: (numbers: string[]) => Promise<void>;
  onClose: () => void;
};

const AddNumbersDialog = ({
  open,
  loading,
  title,
  onSubmit,
  onClose,
}: Props): ReactElement => {
  const handleClose = (
    _,
    reason: "escapeKeyDown" | "backdropClick" | string
  ) => {
    if (reason === "escapeKeyDown" || reason === "backdropClick") {
      return;
    }

    onClose();
  };

  return (
    <Dialog
      open={open}
      fullWidth
      onClose={handleClose}
      sx={{
        minWidth: "800px",
      }}
      disableEscapeKeyDown
    >
      <AddNumbersForm
        open={open}
        loading={loading}
        title={title}
        onClose={onClose}
        onSubmit={onSubmit}
      />
    </Dialog>
  );
};

export default AddNumbersDialog;
