import { ReactElement } from "react";
import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import validationSchema, { maxLength } from "./validationSchema";
import { HelperText, InputGroup } from "../FormComponents";
import { useFormik } from "formik";
type Props = {
  open: boolean;
  loading: boolean;
  onSubmit: (numbers: string[]) => Promise<void>;
  onClose: () => void;
  title: string;
};

const AddNumbersForm = ({
  loading,
  onSubmit,
  onClose,
  title,
}: Props): ReactElement => {
  const formik = useFormik<{ numbers: string }>({
    initialValues: { numbers: "" },
    onSubmit: async ({ numbers }: { numbers: string }) => {
      const numbersArray = numbers.split(",").map((x) => x.trim());
      await onSubmit(numbersArray);
      formik.resetForm();
    },
    validationSchema,
  });

  return (
    <>
      <DialogTitle>{title}</DialogTitle>
      <form onSubmit={formik.handleSubmit} noValidate>
        <DialogContent sx={{ overflow: "hidden" }}>
          <InputGroup>
            <TextField
              id="numbers"
              name="numbers"
              label="Numbers"
              disabled={loading}
              value={formik.values.numbers}
              required
              onChange={formik.handleChange}
              fullWidth
              error={Boolean(formik.touched.numbers && formik.errors.numbers)}
              helperText={
                (formik.touched.numbers && formik.errors.numbers) ||
                `${formik.values.numbers.length}/${maxLength.numbers}` ||
                " "
              }
            />
            <HelperText>
              Use a comma seperator to add multiple numbers
            </HelperText>
          </InputGroup>
        </DialogContent>
        <DialogActions sx={{ padding: "0 24px 20px" }}>
          <Button type="button" disabled={loading} onClick={onClose}>
            Cancel
          </Button>
          <Button type="submit" disabled={loading} variant="contained">
            {loading ? <CircularProgress size={18} /> : "Submit"}
          </Button>
        </DialogActions>
      </form>
    </>
  );
};

export default AddNumbersForm;
