import { Alert, AlertColor, Snackbar } from "@mui/material";
import { ReactElement, useState } from "react";

type Props = {
  message: string;
  type: AlertColor;
  onClose: () => void;
};

const Toast = ({ message, type, onClose }: Props): ReactElement => {
  const [open, setOpen] = useState(true);

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
    onClose();
  };

  return (
    <Snackbar open={open} onClose={handleClose} autoHideDuration={3000}>
      <Alert onClose={handleClose} severity={type} sx={{ width: "100%" }}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default Toast;
