import React, { ReactElement, useState } from "react";
import Notifications from "./Notifications";
import { Notification, NotificationsContext } from "./notificationsContext";

type Props = { children: React.ReactNode };

const NotificationsProvider = ({ children }: Props): ReactElement => {
  const [notification, setNotification] = useState<Notification | undefined>();

  return (
    <NotificationsContext.Provider
      value={{
        notification,
        setNotification,
        clearNotification: () => {
          setNotification(undefined);
        },
      }}
    >
      <>
        {children}
        <Notifications />
      </>
    </NotificationsContext.Provider>
  );
};

export default NotificationsProvider;
