import { AlertColor } from "@mui/material";
import React from "react";

export type Notification = {
  message: string;
  type: AlertColor;
};

type NotificationsContextType = {
  notification: Notification | undefined;
  setNotification: (notification: Notification) => void;
  clearNotification: () => void;
};

export const NotificationsContext =
  React.createContext<NotificationsContextType>({
    notification: undefined,
    setNotification: () => {},
    clearNotification: () => {},
  });
