import { styled, Box, Typography, FormHelperText } from "@mui/material";

export const InputGroup = styled(Box)`
  margin-bottom: 16px;
`;

export const SectionLabel = styled(Typography)`
  font-weight: 500;
  font-size: 0.8rem;
  margin-bottom: 8px;
  text-transform: uppercase;
`;

export const HelperText = styled(FormHelperText)`
  margin-left: 14px;
`;
