import { ReactElement, useEffect, useState } from "react";
import { collection, DocumentData, getDocs } from "firebase/firestore/lite";
import { ParsedToken } from "firebase/auth";

import { db } from "../../firebase";
import { Box, CircularProgress } from "@mui/material";
import UsersTable from "./Table";

type Props = {
  claims: ParsedToken;
};

const Users = ({ claims }: Props): ReactElement => {
  const [users, setUsers] = useState<DocumentData[]>([]);

  useEffect(() => {
    const getUsers = async () => {
      const usersCollection = collection(db, "users");
      const users = (await getDocs(usersCollection)).docs.map((doc) =>
        doc.data()
      );

      setUsers(users);
    };

    getUsers();
  }, []);

  return users.length ? (
    <UsersTable users={users} canManage={Boolean(claims.admin)} />
  ) : (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <CircularProgress />
    </Box>
  );
};

export default Users;
