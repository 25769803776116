import { DocumentData } from "firebase/firestore/lite";
import { ReactElement, useState } from "react";
import {
  DataGrid,
  GridActionsCellItem,
  GridColumns,
  GridRenderCellParams,
} from "@mui/x-data-grid";
import BlockIcon from "@mui/icons-material/Block";
import CheckIcon from "@mui/icons-material/Check";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { Link, Stack, Tooltip } from "@mui/material";
import { parseResult } from "../../utils";
import { ReportAction } from "../../Types";
import useCallManageReport from "../../hooks/useCallManageReport";

type Props = {
  reports: DocumentData[];
  canManage: boolean;
};

const Table = ({ reports: defaultReports, canManage }: Props): ReactElement => {
  const [reports, setReports] = useState<DocumentData[]>(defaultReports);
  const { manageReport, loading } = useCallManageReport();

  const onManageReportClick = (id: string, action: ReportAction) => {
    manageReport(id, action, (result: ReportAction) => {
      const updatedReport = reports.find((report) => report.id === id);
      setReports([
        ...reports.filter((report) => report.id !== id),
        { ...updatedReport, result },
      ]);
    });
  };

  const columns: GridColumns = [
    {
      field: "id",
      headerName: "ID",
      minWidth: 70,
      flex: 0,
    },
    {
      field: "postId",
      headerName: "Post",

      flex: 0,
      renderCell: (params: GridRenderCellParams<any, any, any>) => {
        console.log(params);

        return (
          <Link
            href={`post/${params.value}?reportId=${params.id}`}
            target="_blank"
            rel="noreferrer"
          >
            View
          </Link>
        );
      },
    },
    {
      field: "username",
      headerName: "Post author",
      minWidth: 200,
      flex: 0,
    },
    {
      field: "reasonType",
      headerName: "Reason",
      minWidth: 200,
      flex: 1,
      renderCell: (params: GridRenderCellParams<any, any, any>) => (
        <Tooltip title={params.formattedValue} placement="bottom-start">
          <span>{params.formattedValue}</span>
        </Tooltip>
      ),
    },
    {
      field: "created",
      headerName: "Created date",
      minWidth: 200,
      type: "dateTime",
      flex: 0,
    },
    {
      field: "result",
      headerName: "Result",
      minWidth: 130,
      flex: 0,
    },
  ];

  if (canManage) {
    columns.push({
      field: "actions",
      type: "actions",
      headerName: "Actions",
      getActions: (params) => [
        <GridActionsCellItem
          onClick={() => onManageReportClick(params.row.id, "reviewed")}
          icon={<CheckIcon />}
          label="Reviewed"
          showInMenu
        />,
        <GridActionsCellItem
          onClick={() => onManageReportClick(params.row.id, "blockPost")}
          icon={<BlockIcon />}
          label="Block post"
          showInMenu
        />,
        <GridActionsCellItem
          onClick={() => onManageReportClick(params.row.id, "blockUser")}
          icon={<RemoveCircleOutlineIcon />}
          label="Block user"
          showInMenu
        />,
      ],
    });
  }

  const rows = reports.map((report) => {
    return {
      ...report,
      reasonType:
        report.reasonType === "other" ? report.description : report.reasonType,
      result: parseResult(report.result),
      created: report.created.toDate(),
    };
  });

  return (
    <DataGrid
      initialState={{
        sorting: {
          sortModel: [{ field: "created", sort: "desc" }],
        },
      }}
      loading={loading}
      rows={rows}
      columns={columns}
      components={{
        NoResultsOverlay: () => (
          <Stack height="100%" alignItems="center" justifyContent="center">
            There are no reports matching your filters.
          </Stack>
        ),
        NoRowsOverlay: () => (
          <Stack height="100%" alignItems="center" justifyContent="center">
            There are no reports.
          </Stack>
        ),
      }}
    />
  );
};

export default Table;
