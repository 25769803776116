import * as yup from "yup";

export const maxLength = {
  numbers: 200,
};
export const isPhone = (text: string) => /^\+[0-9]{7,15}$/.test(text);

const validationSchema = yup.object({
  numbers: yup
    .string()
    .required("This field is required")
    .max(maxLength.numbers, `Maximum length is ${maxLength.numbers}`)
    .test(
      "phoneNumbers",
      "Phone numbers should be in the following format +49000000000",
      (numbers) => {
        const numbersArray: string[] = numbers?.split(",") ?? [];
        const areAllNumbersValid = !numbersArray.some(
          (number) => !isPhone(number.trim())
        );

        return areAllNumbersValid;
      }
    ),
});

export default validationSchema;
