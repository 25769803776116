export const parseResult = (
  result: "reviewed" | "blockPost" | "blockUser"
): string => {
  const results = {
    reviewed: "Reviewed",
    blockPost: "Blocked post",
    blockUser: "Blocked user",
  };
  return results[result] ?? "Pending";
};
