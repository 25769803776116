import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Reports from "./Pages/Reports/Reports";
import Users from "./Pages/Users/Users";
import Login from "./Pages/Login";
import ErrorPage from "./Pages/ErrorPage";
import ProtectedPage from "./Pages/ProtectedPage";
import { CssBaseline } from "@mui/material";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "./firebase";
import NotificationsProvider from "./Common/Notifications/NotificationsProvider";
import Post from "./Pages/Post/Post";
import FakeNumbers from "./Pages/FakeNumbers/FakeNumbers";

function App() {
  const [user, loading] = useAuthState(auth);
  return !loading ? (
    <>
      <CssBaseline />
      <NotificationsProvider>
        <BrowserRouter>
          <Routes>
            <Route
              path="/"
              element={<Navigate to={user ? "/reports" : "/login"} />}
            />
            <Route
              path="reports"
              element={
                <ProtectedPage>
                  {(claims) => <Reports claims={claims} />}
                </ProtectedPage>
              }
            />
            <Route
              path="users"
              element={
                <ProtectedPage>
                  {(claims) => <Users claims={claims} />}
                </ProtectedPage>
              }
            />
            <Route
              path="fakeNumbers"
              element={
                <ProtectedPage>
                  {(claims) => <FakeNumbers claims={claims} />}
                </ProtectedPage>
              }
            />
            <Route
              path="post/:id"
              element={
                <ProtectedPage>
                  {(claims) => <Post claims={claims} />}
                </ProtectedPage>
              }
            />
            <Route path="login" element={<Login />} />
            <Route path="*" element={<ErrorPage />}></Route>
          </Routes>
        </BrowserRouter>
      </NotificationsProvider>
    </>
  ) : (
    <></>
  );
}

export default App;
