import { Box, Button, CircularProgress } from "@mui/material";
import BlockIcon from "@mui/icons-material/Block";
import CheckIcon from "@mui/icons-material/Check";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { DocumentData } from "firebase/firestore/lite";
import useCallManageReport from "../../hooks/useCallManageReport";
import { parseResult } from "../../utils";
import { useState } from "react";
import { ReportAction } from "../../Types";

type Props = {
  initialReport: DocumentData;
  canManage: boolean;
};

export const Report = ({ initialReport, canManage }: Props) => {
  const [report, setReport] = useState<DocumentData>(initialReport);
  const { manageReport, loading } = useCallManageReport();

  const ActionButton = ({
    action,
    label,
    icon,
  }: {
    action: ReportAction;
    label: string;
    icon: React.ReactNode;
  }) =>
    action === report.result ? (
      <></>
    ) : (
      <Button
        size="small"
        color="inherit"
        onClick={async () =>
          await manageReport(initialReport.id, action, (result: ReportAction) =>
            setReport({ ...report, result })
          )
        }
      >
        <Box
          sx={{
            whiteSpace: "nowrap",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "6px",
          }}
        >
          {icon} {label}
        </Box>
      </Button>
    );

  const getFormattedCreatedDate = () => {
    const date = new Date(report.created.seconds * 1000);
    return `${date.toLocaleDateString()}, ${date.toLocaleTimeString()}`;
  };

  return (
    <Box sx={{ borderLeft: "1px solid #c1c1c1", paddingLeft: "30px" }}>
      <Box sx={{ fontSize: "22px", fontWeight: 500, marginBottom: "18px" }}>
        Report
      </Box>
      <Box sx={{ fontSize: "18px", fontWeight: 500 }}>Reason</Box>
      <Box sx={{ marginBottom: "18px" }}>
        {report.reasonType === "other" ? report.description : report.reasonType}
      </Box>
      <Box sx={{ fontSize: "18px", fontWeight: 500 }}>Status</Box>
      <Box sx={{ marginBottom: "18px" }}>
        {loading ? <CircularProgress size={18} /> : parseResult(report.result)}
      </Box>
      <Box sx={{ fontSize: "18px", fontWeight: 500 }}>Created date</Box>
      <Box sx={{ marginBottom: "18px" }}>{getFormattedCreatedDate()}</Box>
      {canManage && (
        <>
          <Box sx={{ fontSize: "18px", fontWeight: 500 }}>Actions</Box>
          <ActionButton
            action="reviewed"
            label="Reviewed"
            icon={<CheckIcon htmlColor="rgba(0,0,0,0.54)" />}
          />
          <ActionButton
            action="blockPost"
            label="Block post"
            icon={<BlockIcon htmlColor="rgba(0,0,0,0.54)" />}
          />
          <ActionButton
            action="blockUser"
            label="Block user"
            icon={<RemoveCircleOutlineIcon htmlColor="rgba(0,0,0,0.54)" />}
          />
        </>
      )}
    </Box>
  );
};
