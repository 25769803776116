import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  CircularProgress,
} from "@mui/material";
import { ReactElement } from "react";

type Props = {
  open: boolean;
  text: string;
  loading: boolean;
  onCancel: () => void;
  onConfirm: () => void;
};

const ConfirmDialog = ({
  open,
  text,
  loading,
  onCancel,
  onConfirm,
}: Props): ReactElement => {
  return (
    <Dialog open={open} onClose={onCancel}>
      <DialogTitle>Warning</DialogTitle>
      <DialogContent>
        <DialogContentText>{text}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} disabled={loading}>
          Cancel
        </Button>
        <Button onClick={onConfirm} disabled={loading} autoFocus>
          {loading ? <CircularProgress size={18} /> : "Confirm"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
