import { ReactElement, useContext } from "react";
import { NotificationsContext } from "./notificationsContext";
import Toast from "../Toast";

const Notifications = (): ReactElement => {
  const notificationsContext = useContext(NotificationsContext);
  const notification = notificationsContext.notification;

  return notification ? (
    <Toast
      message={notification.message}
      type={notification.type}
      onClose={notificationsContext.clearNotification}
    />
  ) : (
    <></>
  );
};

export default Notifications;
