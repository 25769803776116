import {
  ParsedToken,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import { auth } from "./firebase";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const trySignIn = async (
  email: string,
  password: string
): Promise<boolean> => {
  const user = await signInWithEmailAndPassword(auth, email, password);
  const { claims } = await user.user.getIdTokenResult();

  const canLogin = claims.admin || claims.admin_readonly;

  if (canLogin) {
    return true;
  }

  await signOut(auth);

  return false;
};

export const canAccess = (claims: ParsedToken, validClaims: string[]) => {
  for (const claim of validClaims) {
    if (claims[claim]) {
      return true;
    }
  }
  return false;
};

export const useValidateAccess = (
  claims: ParsedToken,
  validClaims: string[]
) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!canAccess(claims, validClaims)) {
      signOut(auth);
    }
  }, [claims, validClaims, navigate]);
};
