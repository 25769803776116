import { useEffect } from "react";
import { useHttpsCallable } from "react-firebase-hooks/functions";
import { useShowNotification } from "../Common/Notifications/useShowNotification";
import { functions } from "../firebase";
import { ReportAction } from "../Types";

const useCallManageReport = () => {
  const [callManageReport, loading, error] = useHttpsCallable<
    { id: string; action: string },
    { status: number; result: ReportAction; message: string }
  >(functions, "manageReport");

  useEffect(() => {
    if (error) {
      showNotification(error.message, "error");
    }
  }, [error]);

  const showNotification = useShowNotification();

  const manageReport = async (
    id: string,
    action: ReportAction,
    onSuccess: (result: ReportAction) => void
  ) => {
    const response = await callManageReport({ id, action });

    if (response?.data.status === 201) {
      onSuccess(response?.data.result);
      showNotification(response.data.message, "success");
    }
  };

  return { manageReport, loading, error };
};

export default useCallManageReport;
