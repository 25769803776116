import { ReactElement, useEffect, useState } from "react";
import { collection, DocumentData, getDocs } from "firebase/firestore/lite";
import { ParsedToken } from "firebase/auth";

import { db } from "../../firebase";
import { Box, CircularProgress } from "@mui/material";
import ReportsTable from "./Table";

type Props = {
  claims: ParsedToken;
};

const Reports = ({ claims }: Props): ReactElement => {
  const [reports, setReports] = useState<DocumentData[]>([]);

  useEffect(() => {
    const getReports = async () => {
      const reportsCollection = collection(db, "reports");
      const reports = (await getDocs(reportsCollection)).docs.map((doc) =>
        doc.data()
      );

      setReports(reports);
    };

    getReports();
  }, []);

  return reports.length ? (
    <ReportsTable reports={reports} canManage={Boolean(claims.admin)} />
  ) : (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <CircularProgress />
    </Box>
  );
};

export default Reports;
