import { Box, CardMedia, Link } from "@mui/material";
import { ParsedToken } from "firebase/auth";
import { DocumentData, getDoc, doc } from "firebase/firestore/lite";
import React, { ReactElement, useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { LoadingPage } from "../../Common/LoadingPage";
import { useShowNotification } from "../../Common/Notifications/useShowNotification";
import { db } from "../../firebase";
import ErrorPage from "../ErrorPage";
import { Report } from "./Report";

type Props = {
  claims: ParsedToken;
};

const Post = ({ claims }: Props): ReactElement => {
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const showNotification = useShowNotification();

  const [post, setPost] = useState<DocumentData | undefined>();
  const [report, setReport] = useState<DocumentData | undefined>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getData = async () => {
      if (!id) {
        return;
      }
      setLoading(true);

      let postRef = doc(db, "posts", id);
      let post = await (await getDoc(postRef)).data();

      if (!post) {
        postRef = doc(db, "deletedPosts", id);
        post = await (await getDoc(postRef)).data();
      }

      if (!post) {
        showNotification("Post not found", "error");
        setLoading(false);
        return;
      }
      const reportId = searchParams.get("reportId");
      const report = reportId
        ? await (await getDoc(doc(db, "reports", reportId))).data()
        : undefined;

      setPost(post);
      setReport(report);
      setLoading(false);
    };

    getData();
  }, [id]);

  const renderPost: {
    [key: string]: (p: DocumentData) => React.ReactNode;
  } = {
    link: (p: DocumentData) => (
      <Link
        href={p.url}
        target="_blank"
        rel="noreferrer"
        underline="none"
        sx={{
          borderRadius: "10px",
          backgroundColor: "#58077F",
          padding: "10px 20px",
          color: "#fff",
          "&:hover": {
            backgroundColor: "#c058f3",
          },
        }}
      >
        OPEN LINK
      </Link>
    ),
    text: (p: DocumentData) => (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          fontSize: "24px",
          fontWeight: 500,
          color: "#fff",
          backgroundImage: p.url ? `url(${p.url})` : undefined,
          width: "100%",
          height: "100%",
        }}
      >
        <Box>{p.description}</Box>
      </Box>
    ),
    voice: (p: DocumentData) => (
      <CardMedia component="audio" src={p.url} controls autoPlay />
    ),
    video: (p: DocumentData) => (
      <CardMedia component="video" src={p.url} controls autoPlay></CardMedia>
    ),
    image: (p: DocumentData) => (
      <CardMedia component="img" src={p.url} alt={p.description} />
    ),
  };

  const topGradientColor = post?.topGradientColor || "rgba(8,0,36,1) 0%";
  const bottomGradientColor =
    post?.bottomGradientColor || "rgba(86,9,121,1) 91%";

  return loading ? (
    <LoadingPage />
  ) : post ? (
    <Box
      sx={{
        display: "flex",
        alignItems: "flex-start",
        gap: "25%",
        height: "100%",
        paddingLeft: "300px",
        paddingRight: "100px",
        marginBottom: "100px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexShrink: 0,
          width: 360,
          height: 640,
          borderRadius: 20,
          background: `linear-gradient(to top, ${topGradientColor}, ${bottomGradientColor})`,
        }}
      >
        {renderPost[post.type as string](post)}
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Box sx={{ paddingRight: "30px" }}>
          <Box sx={{ fontSize: "18px", fontWeight: 500, marginBottom: "18px" }}>
            {post.username}
          </Box>
          <Box sx={{ color: "#939393", fontSize: "24px" }}>
            {post.description}
          </Box>
        </Box>
        {report && (
          <Report initialReport={report} canManage={Boolean(claims.admin)} />
        )}
      </Box>
    </Box>
  ) : (
    <ErrorPage />
  );
};

export default Post;
