import { DocumentData } from "firebase/firestore/lite";
import { ReactElement, useEffect, useState } from "react";
import {
  DataGrid,
  GridActionsCellItem,
  GridColumns,
  GridRenderCellParams,
} from "@mui/x-data-grid";
import { Stack, Tooltip, Typography, useTheme } from "@mui/material";
import { useHttpsCallable } from "react-firebase-hooks/functions";
import { functions } from "../../firebase";
import { useShowNotification } from "../../Common/Notifications/useShowNotification";
import { Lock, LockOpen } from "@mui/icons-material";

type Props = {
  users: DocumentData[];
  canManage: boolean;
};

const Table = ({ users: defaultUsers, canManage }: Props): ReactElement => {
  const [users, setUsers] = useState<DocumentData[]>(defaultUsers);

  const [callManageUser, loading, error] = useHttpsCallable<
    { id: string; action: string },
    { status: number; user: object; message: string }
  >(functions, "manageUser");

  const showNotification = useShowNotification();
  const theme = useTheme();

  const manageUser = async (id: string, action: "block" | "unblock") => {
    const response = await callManageUser({ id, action });

    if (response?.data.status === 201) {
      setUsers([...users.filter((user) => user.id !== id), response.data.user]);
      showNotification(response.data.message, "success");
    }
  };

  useEffect(() => {
    if (error) {
      showNotification(error.message, "error");
    }
  }, [error]);

  const columns: GridColumns = [
    {
      field: "id",
      headerName: "ID",
      minWidth: 70,
      flex: 0,
    },
    {
      field: "username",
      headerName: "Username",
      minWidth: 200,
      flex: 0,
    },
    {
      field: "givenName",
      headerName: "Given name",
      minWidth: 200,
      flex: 0,
    },
    {
      field: "familyName",
      headerName: "Family name",
      minWidth: 200,
      flex: 0,
    },
    {
      field: "phoneNumbers",
      headerName: "Phone",
      minWidth: 200,
      flex: 1,
    },
    {
      field: "country",
      headerName: "Country",
      minWidth: 40,
      flex: 0,
    },
    {
      field: "blocked",
      headerName: "Blocked",
      minWidth: 70,
      flex: 0,
      renderCell: (params: GridRenderCellParams<any, any, any>) => {
        return (
          <Typography
            color={
              params.value
                ? theme.palette.error.main
                : theme.palette.success.main
            }
          >
            {params.value ? "yes" : "no"}
          </Typography>
        );
      },
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      getActions: (params) => [
        <Tooltip title="Block" placement="bottom-start">
          <GridActionsCellItem
            onClick={() => manageUser(params.row.id, "block")}
            icon={<Lock color="error" />}
            label="Block"
          />
        </Tooltip>,
        <Tooltip title="Unblock" placement="bottom-start">
          <GridActionsCellItem
            onClick={() => manageUser(params.row.id, "unblock")}
            icon={<LockOpen color="success" />}
            label="Unblock"
          />
        </Tooltip>,
      ],
    },
  ];

  return (
    <DataGrid
      loading={loading}
      rows={users}
      columns={columns}
      components={{
        NoResultsOverlay: () => (
          <Stack height="100%" alignItems="center" justifyContent="center">
            There are no users matching your filters.
          </Stack>
        ),
        NoRowsOverlay: () => (
          <Stack height="100%" alignItems="center" justifyContent="center">
            There are no users.
          </Stack>
        ),
      }}
      initialState={{
        sorting: {
          sortModel: [{ field: "username", sort: "asc" }],
        },
        columns: {
          columnVisibilityModel: {
            actions: canManage,
          },
        },
      }}
    />
  );
};

export default Table;
