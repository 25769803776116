import { ReactElement, useState } from "react";
import { DataGrid, GridActionsCellItem, GridColumns } from "@mui/x-data-grid";
import { Stack, Tooltip } from "@mui/material";
import { Delete } from "@mui/icons-material";
import ConfirmDialog from "../../Common/ConfirmDialog/ConfirmDialog";
import FakeNumber from "./FakeNumber";

type Props = {
  numbers: FakeNumber[];
  canManage: boolean;
  loading: boolean;
  removeFromList: (number: string, onSuccess: () => void) => Promise<void>;
};

const Table = ({
  numbers,
  canManage,
  loading,
  removeFromList,
}: Props): ReactElement => {
  const [selectedNumber, setSelectedNumber] = useState<string>();

  const columns: GridColumns = [
    {
      field: "number",
      headerName: "Number",
      flex: 1,
    },
    {
      field: "code",
      headerName: "Code",
      flex: 1,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      getActions: (params) => [
        <Tooltip title="Delete" placement="bottom-start">
          <GridActionsCellItem
            onClick={() => setSelectedNumber(params.row.number)}
            icon={<Delete color="error" />}
            label="Delete"
          />
        </Tooltip>,
      ],
    },
  ];

  return (
    <>
      <DataGrid
        getRowId={(row) => row.number}
        loading={loading}
        rows={numbers.map(({ number, code }) => ({ number, code }))}
        columns={columns}
        components={{
          NoResultsOverlay: () => (
            <Stack height="100%" alignItems="center" justifyContent="center">
              There are no numbers matching your filters.
            </Stack>
          ),
          NoRowsOverlay: () => (
            <Stack height="100%" alignItems="center" justifyContent="center">
              No numbers have been added yet.
            </Stack>
          ),
        }}
        initialState={{
          sorting: {
            sortModel: [{ field: "number", sort: "asc" }],
          },
          columns: {
            columnVisibilityModel: {
              actions: canManage,
            },
          },
        }}
      />
      <ConfirmDialog
        open={!!selectedNumber}
        loading={loading}
        onCancel={() => {
          setSelectedNumber(undefined);
        }}
        onConfirm={async () => {
          await removeFromList(selectedNumber, () =>
            setSelectedNumber(undefined)
          );
        }}
        text={`Are you sure you want to remove number ${selectedNumber} from the list?`}
      />
    </>
  );
};

export default Table;
