import { useContext } from "react";
import { NotificationsContext } from "./notificationsContext";
import { AlertColor } from "@mui/material";

export const useShowNotification = () => {
  const notificationsContext = useContext(NotificationsContext);

  const showNotification = (message: string, type: AlertColor) => {
    notificationsContext.setNotification({ message, type });
  };

  return showNotification;
};
