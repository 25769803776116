import { Box } from "@mui/material";
import { ReactElement, useEffect, useState } from "react";
import { ParsedToken, signOut } from "firebase/auth";
import { auth } from "../firebase";
import { useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import Navigation from "../Common/Navigation/Navigation";

type Props = {
  children: ((claims: ParsedToken) => React.ReactNode) | React.ReactNode;
};

const canAccess = (claims: ParsedToken) => {
  return claims.admin || claims.admin_readonly;
};

const ProtectedPage = ({ children }: Props): ReactElement => {
  const [user, loading] = useAuthState(auth);
  const [claims, setClaims] = useState<ParsedToken | undefined>();
  const navigate = useNavigate();

  useEffect(() => {
    if (!loading && !user) {
      navigate("/login");

      return;
    }

    getClaims();

    async function getClaims(): Promise<void> {
      setClaims((await user?.getIdTokenResult())?.claims);
    }
  }, [user, loading, navigate]);

  return claims && canAccess(claims) ? (
    <Box
      sx={{
        padding: "20px 24px",
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Navigation signOut={() => signOut(auth)} />
      <Box
        sx={{
          flexGrow: 1,
        }}
      >
        {typeof children === "function" ? children(claims) : children}
      </Box>
    </Box>
  ) : (
    <></>
  );
};

export default ProtectedPage;
